import OpenAI from "openai";
import DOMPurify from "dompurify";

const openai = new OpenAI({
  apiKey: "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",
  dangerouslyAllowBrowser: true,
});

function addLinkAttributes(content) {
  return content.replace(/<a/g, `<a target="_blank" rel="noopener noreferrer"`);
}

function sanitizeAndFormatHTML(html) {
  return addLinkAttributes(DOMPurify.sanitize(html));
}

export async function sendMessage(message) {
  const response = await openai.chat.completions.create({
    messages: [
      {
        role: "user",
        content:
          message +
          ` -- Format your response as HTML. Valid tags you can use: <p>, <br>, <ol>, <ul>, <b>, <strong>. At the bottom, include formatted working links.`,
      },
    ],
    model: "gpt-3.5-turbo",
  });

  const htmlContent = response.choices[0].message.content;

  return sanitizeAndFormatHTML(htmlContent);
}
