export default function ChatThreads({ chatIds, setChatId }) {
  const openNewChat = () => {
    window.location.reload();
  };

  const formatDate = (chatId) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(parseInt(chatId));
    if (isNaN(date)) return "";
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, "0");
    let hours = date.getHours();
    const mins = date.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours.toString().padStart(2, "0");

    return `${month} ${day}, ${hours}:${mins} ${amPm}`;
  };

  return (
    <div className="sidebar">
      <button className="newChat" onClick={openNewChat}>
        + New Chat
      </button>

      <div id="chatThreads">
        <p>Previous Conversations</p>
        {chatIds.map((chatId) => (
          <button id="chatButton" onClick={() => setChatId(chatId)}>
            {formatDate(chatId)}
          </button>
        ))}
      </div>
    </div>
  );
}
