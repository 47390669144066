import { useState } from "react";
import "./App.css";
import Chat from "./components/Chat";
import ChatThreads from "./components/ChatThreads";
import { getCookie } from "./common/cookies";

function App() {
  const [chatId, setChatId] = useState(new Date().getTime().toString());
  const [chatIds, setChatIds] = useState(getCookie("chatIds").split(","));

  return (
    <div className="App">
      <div className="main">
        <ChatThreads chatIds={chatIds} setChatId={setChatId} />
        <Chat chatId={chatId} setChatIds={setChatIds} />
      </div>
    </div>
  );
}

export default App;
