import sendIcon from "../assets/sendicon.svg";
import circleIcon from "../assets/circleicon.png";
import blueCircleIcon from "../assets/bluecircleicon.png";
import loadingGIF from "../assets/loading.gif";
import { useEffect, useState, useRef } from "react";
import { sendMessage } from "./OpenAI";
import parse from "html-react-parser";
import { getCookie, setCookie } from "../common/cookies";

function updateChatCookies(chatId, messages, setChatIds) {
  setCookie(chatId, JSON.stringify(messages));

  let chats = getCookie("chatIds");

  if (chats.indexOf(chatId) == -1) {
    if (chats !== "") chats += ",";
    chats += chatId;
    setChatIds((chatIds) => [...chatIds, chatId]);
  }
  setCookie("chatIds", chats);
}

const INITIAL_MESSAGE = {
  text: "Hello, I am Jinship, a disaster relief assistant. How can I assist you?",
  isAI: true,
};

function parseMessagesFromString(messageStr) {
  if (messageStr === "") {
    return [INITIAL_MESSAGE];
  }
  return JSON.parse(messageStr);
}

export default function Chat({ chatId, setChatIds }) {
  const endMessage = useRef(null);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([INITIAL_MESSAGE]);

  useEffect(() => {
    setMessages(parseMessagesFromString(getCookie(chatId)));
  }, [chatId]);

  useEffect(() => {
    if (endMessage.current) endMessage.current.scrollIntoView();
  }, [messages]);

  const submitChat = async () => {
    setLoading(true);
    const text = input;
    setInput("");
    setMessages([...messages, { text, isAI: false }]);
    const response = await sendMessage(text);
    console.log(response);

    const newMessages = [
      ...messages,
      { text, isAI: false, isFormattedHtml: false },
      { text: response, isAI: true, isFormattedHtml: true },
    ];
    setMessages(newMessages);

    updateChatCookies(chatId, newMessages, setChatIds);

    setLoading(false);
  };

  const submitOnEnter = async (e) => {
    if (e.key === "Enter") await submitChat();
  };

  return (
    <div className="chat">
      <div className="chatArea">
        <div className="messages">
          {messages.map((message, i) => (
            <div
              key={i}
              className={message.isAI ? "responseDisplay" : "inputDisplay"}
            >
              <img
                src={message.isAI ? blueCircleIcon : circleIcon}
                alt="Profile"
                height="40rem"
                width="40rem"
              />
              <div className={message.isAI ? "response" : "inputMessage"}>
                {message.isFormattedHtml ? parse(message.text) : message.text}
                <div ref={endMessage} />
              </div>
            </div>
          ))}
        </div>
        {loading && (
          <div id="loadingArea" className="responseDisplay">
            <img
              src={blueCircleIcon}
              alt="Profile"
              height="40rem"
              width="40rem"
            />
            <div className="response">
              <img id="loadingGif" src={loadingGIF} alt="Loading animation" />
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <div className="input">
          <input
            type="text"
            placeholder="Message Jinship"
            value={input}
            onKeyDown={submitOnEnter}
            onChange={(e) => setInput(e.target.value)}
          />
          <button className="send" onClick={submitChat}>
            <img src={sendIcon} alt="Send" height="35rem" />
          </button>
        </div>
      </div>
    </div>
  );
}
